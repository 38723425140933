import React from 'react'
import Navbar from '../src/components/theme/Layouts/NavbarSimplybook'
import Footer from '../src/components/theme/Layouts/Footer'
import Image from 'next/legacy/image'
import { useTranslation } from 'next-i18next'
import { NextPageContext } from 'next'


const Error = ({ statusCode }) => {
    const { t, ready } = useTranslation()
    const status = statusCode
    const message =
    status === 404
        ? t('OOPS!! The page that you are looking is not found.')
        : `${status}  Error `

    return (
        <>
            {ready &&
        <>

            <Navbar withoutBanner={true} />
            <section
                className="page-title-area jarallax"
                data-jarallax='{"speed": 0.2}'
            >
                <div className="container">
                    <h2>{message}</h2>
                </div>
            </section>

            <section className="error-area ptb-100">
                <div className="container">
                    <div className="contact-connect-custom">
                        <a href="/" className="btn btn-primary mt-3" rel="noopener noreferrer">
                            {t('Go to Home Page')}
                        </a>
                    </div>
                    <div className="error-content">
                        {status === 404 ? (
                            <Image
                                src="/images/assets/404.png"
                                alt="Sorry. The page is not found"
                                objectFit="contain"
                                width={800}
                                height={400}
                            />
                        ) : (
                            <div />
                        )}
                    </div>
                </div>

            </section>
            <Footer />
        </>}
        </>
    )
}

Error.getInitialProps = (ctx: NextPageContext) => {
    const { res, err } = ctx
    const statusCode = res ? res.statusCode : err ? err.statusCode : null
    console.error('Error.getInitialProps', { err, res, ctx })
    return { statusCode }
}

export default Error
